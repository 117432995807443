/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import { Header, Footer, Responsive } from 'client/components/common';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import introD from 'assets/client/images/intro-d.jpg';
import introM from 'assets/client/images/intro-mb.jpg';

import ClosedActivityPopup from 'client/components/common/Popups/ClosedActivityPopup';
import ContentForm from 'client/components/common/ContentForm';
import * as css from './Intro.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
|};

type State = {};
class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	state = {};

	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	render() {
		const { className, transitionState } = this.props;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		return (
			<div className={classNames(css.intro, css[transitionState], className)} key="main">
				<Header isMobile={Responsive.isMatching(Responsive.MOBILE)} />
				<div className={css.introImg}>
					<img src={isMobile ? introM : introD} alt="" />
				</div>
				<ContentForm />
				<Footer />
				<ClosedActivityPopup className="show" />
			</div>
		);
	}
}

export default withTransition(Intro);
