/* eslint-disable react/no-unused-prop-types */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import { Select } from 'antd';
import _ from 'lodash';

// import arrowDown from '../../../../../../assets/client/images/arrow-down.svg';

import * as css from './Select.scss';

type Props = {
	input: {|
		name: string,
		onBlur: Function,
		onChange: Function,
		onDragStart: Function,
		onDrop: Function,
		onFocus: Function,
		value?: Array<string> | string,
	|},
	meta: {|
		active?: boolean,
		asyncValidating?: boolean,
		autofilled?: boolean,
		dirty?: boolean,
		dispatch?: Function,
		error?: string,
		form?: string,
		initial?: string,
		invalid?: boolean,
		pristine?: boolean,
		submitFailed?: boolean,
		submitting?: boolean,
		touched?: boolean,
		valid?: boolean,
		visited?: boolean,
		warning?: string,
	|},
	inputRef?: { current: ?HTMLInputElement } | null,
	className?: string,
	placeholder?: string,
	icon?: React.Node,
	options: Array<SelectOption>,
	mode?: 'single' | 'multiply',
	eventListeners: {|
		onChange?: Function,
		onFocus?: Function,
		onBlur?: Function,
		onKeyDown?: Function,
		onKeyUp?: Function,
	|},
};

export default class Input extends React.PureComponent<Props> {
	static defaultProps = {
		inputRef: null,
		className: '',
		placeholder: '',
		mode: 'single',
		icon: null,
	};

	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	componentDidUpdate() {}

	renderOptions = () => {
		const { options } = this.props;

		return _.map<SelectOption, React.Element<any>>(options, (option: SelectOption) => (
			<Select.Option key={option.id}>{option.label}</Select.Option>
		));
	};

	onChange = (value: Array<string>) => {
		const { input } = this.props;
		input.onChange(value);
	};

	render() {
		const { className, placeholder, mode, input, icon } = this.props;

		return (
			<label className={classNames(css.selectField, icon && css.withIcon, className)}>
				<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M2.55981 6.74424L3.98434 5.3335L10.5598 11.8454L17.1353 5.3335L18.5598 6.74424L10.5598 14.6668L2.55981 6.74424Z"
						fill="#FF212C"
					/>
				</svg>
				<Select
					mode={mode}
					value={input.value || undefined}
					onChange={this.onChange}
					placeholder={placeholder}
					getPopupContainer={trigger => trigger.parentNode}
				>
					{this.renderOptions()}
				</Select>
			</label>
		);
	}
}
