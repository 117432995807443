/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import * as React from 'react';
import classNames from 'classnames';
import validator from 'validator';

import { LEAD_CONSTANTS, CAN_USE_DOM } from 'common/constants';
import Icon from 'common/components/Icon';

import { PLACEHOLDERS_TEXTS, COLLECTIONS, ERRORS_TEXTS } from 'client/constants';
import { Form, TextField, Checkbox, Radio, Select, Responsive } from 'client/components/common';
import { initialize, reset } from 'redux-form';
import { createItem } from 'client/actions';
import { dataLayerEvents } from 'common/utils/gtag';
import termsPdfFile from 'assets/client/terms2024.pdf';
import SendedFormPopup from '../Popups/SendedFormPopup';
import * as css from './ContentForm.scss';
import AlreadyInSystemPopup from '../Popups/AlreadyInSystemPopup';
import { validateEmail } from '../../../utils/vallidateEmail';
import NotStartedPopup from '../Popups/NotStartedPopup';

// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;
// eslint-disable-next-line
const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
type FormData = {
	fullName?: string,
	locality?: string,
	kindergarten?: string,
	phoneNumber?: string,
	email?: string,
	promotingAcademicAchievement?: string,
	socialSkillsEducation?: string,
	contactWithCommunity?: string,
	kindergartenEmotionalDev?: string,
	uniqueContribution?: string,
	opinion?: string,
	teacherInfluenceExample?: string,
	whatMakesTeacherSpecial?: string,
	valuesAndLessons?: string,
	recommenderFullName?: string,
	recommenderPhone?: string,
	recommenderEmail?: string,
	recommenderRelationship?: string,
	isStateKindergarten?: boolean | string,
	confirmCheckbox?: boolean | string,
};

type Props = {
	values: FormData,
	errors: FormData,
	fields: { [key: string]: { touched: boolean } },
	createItem: typeof createItem,
	resetForm: Function,
};

type State = {
	isUserNotified: boolean,
	isShowSenededFormPopup: boolean,
	isShowAlreadyInSystemPopup: boolean,
	isLoading: boolean,
};

const FORM_CONFIG = {
	form: 'userInfo',
	validate: (values: FormData) => {
		const errorsObj = {};

		if (!values.fullName) {
			errorsObj.fullName = ERRORS_TEXTS.fullName;
		}

		if (!values.kindergarten) {
			errorsObj.kindergarten = ERRORS_TEXTS.kindergarten;
		}

		if (!values.locality) {
			errorsObj.locality = ERRORS_TEXTS.locality;
		}

		if (!values.phoneNumber) {
			errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumber;
		} else if (values.phoneNumber) {
			const validPhone = phoneRegExp.test(values.phoneNumber.toLocaleLowerCase());
			if (!validPhone) {
				errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumberNotValid;
			}
		}

		if (values.email) {
			const validEmail = validateEmail(values.email.trim());
			if (!validEmail) {
				errorsObj.email = ERRORS_TEXTS.emailNotValid;
			}
		}

		if (!values.promotingAcademicAchievement) {
			errorsObj.promotingAcademicAchievement = ERRORS_TEXTS.radioRequired;
		}
		if (!values.socialSkillsEducation) {
			errorsObj.socialSkillsEducation = ERRORS_TEXTS.radioRequired;
		}
		if (!values.contactWithCommunity) {
			errorsObj.contactWithCommunity = ERRORS_TEXTS.radioRequired;
		}
		if (!values.kindergartenEmotionalDev) {
			errorsObj.kindergartenEmotionalDev = ERRORS_TEXTS.radioRequired;
		}
		if (!values.uniqueContribution) {
			errorsObj.uniqueContribution = ERRORS_TEXTS.radioRequired;
		}

		if (!values.opinion) {
			errorsObj.opinion = ERRORS_TEXTS.textareaRequired;
		} else if (values.opinion) {
			const opinionLength = charactersCount(values.opinion);

			if (opinionLength > 1000) {
				errorsObj.opinion = ERRORS_TEXTS.opinionMaximum;
			}
		}
		// if (!values.teacherInfluenceExample) {
		// 	errorsObj.teacherInfluenceExample = ERRORS_TEXTS.textareaRequired;
		// } else if (values.teacherInfluenceExample) {
		// 	const teacherInfluenceExampleLength = charactersCount(values.teacherInfluenceExample);

		// 	if (teacherInfluenceExampleLength > 1000) {
		// 		errorsObj.teacherInfluenceExample = ERRORS_TEXTS.opinionMaximum;
		// 	}
		// }
		// if (!values.whatMakesTeacherSpecial) {
		// 	errorsObj.whatMakesTeacherSpecial = ERRORS_TEXTS.textareaRequired;
		// } else if (values.whatMakesTeacherSpecial) {
		// 	const whatMakesTeacherSpecialExampleLength = charactersCount(values.whatMakesTeacherSpecial);

		// 	if (whatMakesTeacherSpecialExampleLength > 1000) {
		// 		errorsObj.whatMakesTeacherSpecial = ERRORS_TEXTS.opinionMaximum;
		// 	}
		// }
		// if (!values.valuesAndLessons) {
		// 	errorsObj.valuesAndLessons = ERRORS_TEXTS.textareaRequired;
		// } else if (values.valuesAndLessons) {
		// 	const valuesAndLessonsLength = charactersCount(values.valuesAndLessons);

		// 	if (valuesAndLessonsLength > 1000) {
		// 		errorsObj.valuesAndLessons = ERRORS_TEXTS.opinionMaximum;
		// 	}
		// }

		if (!values.recommenderFullName) {
			errorsObj.recommenderFullName = ERRORS_TEXTS.recommenderFullName;
		}
		if (!values.recommenderPhone) {
			errorsObj.recommenderPhone = ERRORS_TEXTS.recommenderPhone;
		} else if (values.recommenderPhone) {
			const validPhone = phoneRegExp.test(values.recommenderPhone.toLocaleLowerCase());
			if (!validPhone) {
				errorsObj.recommenderPhone = ERRORS_TEXTS.recommenderPhoneNotValid;
			}
		}

		if (!values.recommenderEmail) {
			errorsObj.recommenderEmail = ERRORS_TEXTS.recommenderEmail;
		} else if (values.recommenderEmail) {
			const validEmail = validator.isEmail(values.recommenderEmail.trim());

			if (!validEmail) {
				errorsObj.recommenderEmail = ERRORS_TEXTS.recommenderEmailNotValid;
			}
		}

		if (!values.recommenderRelationship) {
			errorsObj.recommenderRelationship = ERRORS_TEXTS.recommenderRelationship;
		}

		if (!values.confirmCheckbox) {
			errorsObj.confirmCheckbox = ERRORS_TEXTS.confirmCheckbox;
		}
		if (!values.isStateKindergarten) {
			errorsObj.isStateKindergarten = ERRORS_TEXTS.isStateKindergarten;
		}
		return errorsObj;
	},
};

const selectOpts2 = [
	{ id: 'הורה לילד/ה בגן', label: 'הורה לילד/ה בגן' },
	{ id: 'גננת עמית/ה', label: 'גננת עמית/ה' },
	{ id: 'מנהל/ת', label: 'מנהל/ת' },
];

const wordCount = value => {
	if (value === undefined) return 0;
	return value.trim().split(' ').length;
};

const charactersCount = value => {
	if (value === undefined) return 0;
	return value.trim().length;
};
class ContentForm extends React.PureComponent<Props, State> {
	// static defaultProps = {
	// 	// className: '',
	// };

	constructor(props: Props) {
		super(props);
		this.state = {
			isUserNotified: false,
			isShowSenededFormPopup: false,
			isShowAlreadyInSystemPopup: false,
			isLoading: false,
		};
	}

	componentDidMount() {
		const isCreatedLead = localStorage.getItem('bestT2024');

		if (isCreatedLead) {
			this.setState({
				isShowAlreadyInSystemPopup: true,
			});
		}
	}
	initFormValues = async () => {};

	onFormSubmit = async (values: FormData) => {
		const { resetForm } = this.props;

		this.setState({
			isUserNotified: false,
			isLoading: true,
		});

		const leadResult = await this.props.createItem<any>({ collection: COLLECTIONS.LEADS, data: values });

		if (leadResult.lead === LEAD_CONSTANTS.CREATED) {
			this.setState({
				isShowSenededFormPopup: true,
				isLoading: false,
			});

			localStorage.setItem('bestT2024', 'true');

			resetForm('userInfo');

			dataLayerEvents.onFormSended();
		} else if (leadResult.lead === LEAD_CONSTANTS.FOUNDED) {
			this.setState({
				isShowAlreadyInSystemPopup: true,
				isLoading: false,
			});

			resetForm('userInfo');
		}
	};

	closeErrorPopup = () => {
		this.setState({
			isUserNotified: true,
			isLoading: false,
		});
	};

	closeAlreadyInSystemPopup = () => {
		this.setState({
			isShowAlreadyInSystemPopup: false,
		});
	};

	closeSuccessPopup = () => {
		this.setState({
			isShowSenededFormPopup: false,
		});
	};

	render() {
		const { errors, values, fields } = this.props;
		const { isShowSenededFormPopup, isShowAlreadyInSystemPopup } = this.state;
		// const isMobile = Responsive.isMatching(Responsive.MOBILE);
		// const errosWithoutRadioBtns = _.omit(errors, [
		// 	'promotingAcademicAchievement',
		// 	'socialSkillsEducation',
		// 	'contactWithCommunity',
		// 	'kindergartenEmotionalDev',
		// 	'uniqueContribution',
		// ]);
		let macOs;

		// console.log('errors', errors);

		const opinionLength = charactersCount(values.opinion);
		const teacherInfluenceExampleLength = charactersCount(values.teacherInfluenceExample);
		const whatMakesTeacherSpecialLength = charactersCount(values.whatMakesTeacherSpecial);
		const valuesAndLessonsLength = charactersCount(values.valuesAndLessons);

		if (CAN_USE_DOM) {
			macOs = /(Mac)/i.test(navigator.platform);
		}

		const isErrors = !_.isEmpty(errors);

		const isErrorFullName = isErrors && !!_.get(errors, 'fullName', '');
		const isErrorKindergarten = isErrors && !!_.get(errors, 'kindergarten', '');
		const isErrorTeacherPhoneNumber =
			isErrors && (!!_.get(errors, 'phoneNumber', '') || !!_.get(errors, 'phoneNumberNotValid', ''));
		const isErrorTeacherEmail = isErrors && (!!_.get(errors, 'email', '') || !!_.get(errors, 'emailNotValid', ''));
		const isErrorLocality = isErrors && !!_.get(errors, 'locality', '');

		const isErrorOpinion = isErrors && !!_.get(errors, 'opinion', '');
		const isErrorTeacherInfluenceExample = isErrors && !!_.get(errors, 'teacherInfluenceExample', '');
		const isErrorWhatMakesTeacherSpecial = isErrors && !!_.get(errors, 'whatMakesTeacherSpecial', '');
		const isErrorValuesAndLessons = isErrors && !!_.get(errors, 'valuesAndLessons', '');

		const isErrorPromotingAcademicAchievement = isErrors && !!_.get(errors, 'promotingAcademicAchievement', '');
		const isErrorSocialSkillsEducation = isErrors && !!_.get(errors, 'socialSkillsEducation', '');
		const isErrorContactWithCommunity = isErrors && !!_.get(errors, 'contactWithCommunity', '');
		const isErrorKindergartenEmotionalDev = isErrors && !!_.get(errors, 'kindergartenEmotionalDev', '');
		const isErrorUniqueContribution = isErrors && !!_.get(errors, 'uniqueContribution', '');

		const isErrorRecommenderFullName = isErrors && !!_.get(errors, 'recommenderFullName', '');
		const isErrorRecommenderPhone =
			isErrors && (!!_.get(errors, 'recommenderPhone', '') || !!_.get(errors, 'recommenderPhoneNotValid', ''));
		const isErrorRecommenderEmail =
			isErrors && (!!_.get(errors, 'recommenderEmail', '') || !!_.get(errors, 'recommenderEmailNotValid', ''));
		const isErrorRecommenderRelationship = isErrors && !!_.get(errors, 'recommenderRelationship', '');

		const isErrorCheckbox = isErrors && !!_.get(errors, 'confirmCheckbox', '');
		const isStateKindergartenError = isErrors && !!_.get(errors, 'isStateKindergarten', '');

		return (
			<div className={css.formWrapper}>
				<Form className={css.form} config={FORM_CONFIG} onSubmit={this.onFormSubmit}>
					<div className={css.title}>
						<h3>פרטי הגננת עליה תרצו להמליץ:</h3>
					</div>

					<div className={classNames(css.formRow, css.fiveInputsRow)}>
						<div className={css.fieldsWrapper}>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									(_.get(fields, 'fullName.active', false) || _.get(values, 'fullName', '')) &&
										css.focusedStar,
									isErrorFullName && _.get(fields, 'fullName.touched', false) && css.error
								)}
							>
								<TextField
									name="fullName"
									placeholder={PLACEHOLDERS_TEXTS.fullName}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorFullName && _.get(fields, 'fullName.touched', false) && (
									<p className={css.inputError}>{errors.fullName}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									(_.get(fields, 'kindergarten.active', false) ||
										_.get(values, 'kindergarten', '')) &&
										css.focusedStar,
									isErrorKindergarten && _.get(fields, 'kindergarten.touched', false) && css.error
								)}
							>
								<TextField
									name="kindergarten"
									placeholder={PLACEHOLDERS_TEXTS.kindergarten}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorKindergarten && _.get(fields, 'kindergarten.touched', false) && (
									<p className={css.inputError}>{errors.kindergarten}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									(_.get(fields, 'locality.active', false) || _.get(values, 'locality', '')) &&
										css.focusedStar,
									isErrorLocality && _.get(fields, 'locality.touched') && css.error
								)}
							>
								<TextField
									name="locality"
									placeholder={PLACEHOLDERS_TEXTS.locality}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorLocality && _.get(fields, 'locality.touched') && (
									<p className={css.inputError}>{errors.locality}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									(_.get(fields, 'phoneNumber.active', false) || _.get(values, 'phoneNumber', '')) &&
										css.focusedStar,
									isErrorTeacherPhoneNumber &&
										_.get(fields, 'phoneNumber.touched', false) &&
										css.error,
									css.withPhone
								)}
							>
								<TextField
									name="phoneNumber"
									placeholder={PLACEHOLDERS_TEXTS.phoneNumber}
									pattern="^[0-9-+]+$"
									isNumberField
									isPlusAtTheStart
									type="tel"
								/>
								{isErrorTeacherPhoneNumber && _.get(fields, 'phoneNumber.touched', false) && (
									<p className={css.inputError}>{errors.phoneNumber}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.withEmail,
									isErrorTeacherEmail && _.get(fields, 'email.touched', false) && css.error
								)}
							>
								<TextField
									name="email"
									placeholder={PLACEHOLDERS_TEXTS.email}
									isEmailField
									type="email"
								/>
								{isErrorTeacherEmail && _.get(fields, 'email.touched', false) && (
									<p className={css.inputError}>{errors.email}</p>
								)}
							</div>
						</div>
					</div>

					<div className={css.radioBtnsGroupTitle}>
						<h3>דרגו את הגננת על פי הקריטריונים הבאים:</h3>
						<p>(5 – במידה רבה מאוד, 1 – במידה מועטה מאוד)</p>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div className={classNames(css.radioBtnsWrapperTitle)}>
								<b>קידום הישגים לימודיים</b>
								<p>
									(למשל: מוכנות לכיתה א', הכרת אותיות וספרות, שיום, צבעים, חגים ומועדים, עונות השנה,
									למידה מותאמת דיפרנציאלית ועוד)
								</p>
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="promotingAcademicAchievement" value="5" label="5" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="promotingAcademicAchievement" value="4" label="4" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="promotingAcademicAchievement" value="3" label="3" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="promotingAcademicAchievement" value="2" label="2" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="promotingAcademicAchievement" value="1" label="1" />
							</div>

							{isErrorPromotingAcademicAchievement &&
								_.get(fields, 'promotingAcademicAchievement.touched', false) && (
								<p className={css.radioError}>{errors.promotingAcademicAchievement}</p>
							)}
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div className={classNames(css.radioBtnsWrapperTitle)}>
								<b>חינוך לערכים חברתיים</b>
								<p>(קבלת האחר והשונה, עזרה לזולת, סוגי משפחות, ערבות הדדית, קשר בין דורי)</p>
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="socialSkillsEducation" value="5" label="5" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="socialSkillsEducation" value="4" label="4" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="socialSkillsEducation" value="3" label="3" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="socialSkillsEducation" value="2" label="2" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="socialSkillsEducation" value="1" label="1" />
							</div>
							{isErrorSocialSkillsEducation && _.get(fields, 'socialSkillsEducation.touched', false) && (
								<p className={css.radioError}>{errors.socialSkillsEducation}</p>
							)}
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.radioBtnsWrapper)}>
							<div className={classNames(css.radioBtnsWrapperTitle)}>
								<b>קשר עם הקהילה וסביבת הגן</b>
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="contactWithCommunity" value="5" label="5" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="contactWithCommunity" value="4" label="4" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="contactWithCommunity" value="3" label="3" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="contactWithCommunity" value="2" label="2" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="contactWithCommunity" value="1" label="1" />
							</div>
							{isErrorContactWithCommunity && _.get(fields, 'contactWithCommunity.touched', false) && (
								<p className={css.radioError}>{errors.contactWithCommunity}</p>
							)}
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.radioBtnsWrapper)}>
							<div className={classNames(css.radioBtnsWrapperTitle)}>
								<b>הגן כסביבה לימודית המפתחת יכולות רגשיות</b>
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="kindergartenEmotionalDev" value="5" label="5" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="kindergartenEmotionalDev" value="4" label="4" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="kindergartenEmotionalDev" value="3" label="3" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="kindergartenEmotionalDev" value="2" label="2" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="kindergartenEmotionalDev" value="1" label="1" />
							</div>
							{isErrorKindergartenEmotionalDev &&
								_.get(fields, 'kindergartenEmotionalDev.touched', false) && (
								<p className={css.radioError}>{errors.kindergartenEmotionalDev}</p>
							)}
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.radioBtnsWrapper, css.smallerPadding)}>
							<div className={classNames(css.radioBtnsWrapperTitle)}>
								<b>תרומה ייחודית לרווחת הילדים ולתחושת הביטחון והרציפות הקשר האישי בתקופת המלחמה</b>
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueContribution" value="5" label="5" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueContribution" value="4" label="4" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueContribution" value="3" label="3" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueContribution" value="2" label="2" />
							</div>
							<div className={css.fieldWrapper}>
								<Radio name="uniqueContribution" value="1" label="1" />
							</div>
							{isErrorUniqueContribution && _.get(fields, 'uniqueContribution.touched', false) && (
								<p className={css.radioError}>{errors.uniqueContribution}</p>
							)}
						</div>
					</div>

					<div className={classNames(css.formRow)}>
						<div className={classNames(css.fieldWrapper, css.fullWidthField)}>
							<TextField
								name="opinion"
								label={PLACEHOLDERS_TEXTS.opinion}
								placeholder=""
								isTextarea
								textareaType={2}
								className={css.textAreaField}
								// maxWordsLength={500}
								// pattern="^[\u0590-\u05FF 0-9 /\W\r\n-]+$"
								maxLength={1000}
								isRequired
							/>
							{isErrorOpinion && _.get(fields, 'opinion.touched') && (
								<p className={css.textareaError}>{errors.opinion}</p>
							)}
							<p className={classNames(css.charCounter, opinionLength > 1000 && css.red)}>
								{opinionLength}/1000
							</p>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.fieldWrapper, css.fullWidthField)}>
							<TextField
								name="teacherInfluenceExample"
								label={PLACEHOLDERS_TEXTS.teacherInfluenceExample}
								placeholder=""
								isTextarea
								textareaType={1}
								className={css.textAreaField}
								// maxWordsLength={150}
								// pattern="^[\u0590-\u05FF 0-9 /\W\r\n-]+$"
								maxLength={1000}
							/>
							{isErrorTeacherInfluenceExample && _.get(fields, 'teacherInfluenceExample.touched') && (
								<p className={css.textareaError}>{errors.teacherInfluenceExample}</p>
							)}
							<p className={classNames(css.charCounter, teacherInfluenceExampleLength > 1000 && css.red)}>
								{teacherInfluenceExampleLength}/1000
							</p>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.fieldWrapper, css.fullWidthField)}>
							<TextField
								name="whatMakesTeacherSpecial"
								label={PLACEHOLDERS_TEXTS.whatMakesTeacherSpecial}
								placeholder=""
								isTextarea
								textareaType={1}
								className={css.textAreaField}
								// maxWordsLength={120}
								// pattern="^[\u0590-\u05FF 0-9 /\W\r\n-]+$"
								maxLength={1000}
							/>
							{isErrorWhatMakesTeacherSpecial && _.get(fields, 'whatMakesTeacherSpecial.touched') && (
								<p className={css.textareaError}>{errors.whatMakesTeacherSpecial}</p>
							)}
							<p className={classNames(css.charCounter, whatMakesTeacherSpecialLength > 1000 && css.red)}>
								{whatMakesTeacherSpecialLength}/1000
							</p>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={classNames(css.fieldWrapper, css.fullWidthField)}>
							<TextField
								name="valuesAndLessons"
								label={PLACEHOLDERS_TEXTS.valuesAndLessons}
								placeholder=""
								isTextarea
								textareaType={1}
								className={css.textAreaField}
								// maxWordsLength={120}
								// pattern="^[\u0590-\u05FF 0-9 /\W\r\n-]+$"
								maxLength={1000}
							/>
							{isErrorValuesAndLessons && _.get(fields, 'valuesAndLessons.touched') && (
								<p className={css.textareaError}>{errors.valuesAndLessons}</p>
							)}
							<p className={classNames(css.charCounter, valuesAndLessonsLength > 1000 && css.red)}>
								{valuesAndLessonsLength}/1000
							</p>
						</div>
					</div>
					<div className={classNames(css.formRow, css.fourInputsRow)}>
						<div className={classNames(css.title)}>
							<h3>פרטי הממליצ/ה</h3>
						</div>
						<div className={css.fieldsWrapper}>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									(_.get(fields, 'recommenderFullName.active', false) ||
										_.get(values, 'recommenderFullName', '')) &&
										css.focusedStar,
									isErrorRecommenderFullName &&
										_.get(fields, 'recommenderFullName.touched') &&
										css.error
								)}
							>
								<TextField
									name="recommenderFullName"
									placeholder={PLACEHOLDERS_TEXTS.recommenderFullName}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorRecommenderFullName && _.get(fields, 'recommenderFullName.touched') && (
									<p className={css.inputError}>{errors.recommenderFullName}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									(_.get(fields, 'recommenderPhone.active', false) ||
										_.get(values, 'recommenderPhone', '')) &&
										css.focusedStar,
									isErrorRecommenderPhone && _.get(fields, 'recommenderPhone.touched') && css.error,
									css.withPhone
								)}
							>
								<TextField
									name="recommenderPhone"
									placeholder={PLACEHOLDERS_TEXTS.recommenderPhone}
									pattern="^[0-9-+]+$"
									isNumberField
									isPlusAtTheStart
									type="tel"
								/>
								{isErrorRecommenderPhone && _.get(fields, 'recommenderPhone.touched') && (
									<p className={css.inputError}>{errors.recommenderPhone}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									(_.get(fields, 'recommenderEmail.active', false) ||
										_.get(values, 'recommenderEmail', '')) &&
										css.focusedStar,
									css.withEmail,
									isErrorRecommenderEmail && _.get(fields, 'recommenderEmail.touched') && css.error
								)}
							>
								<TextField
									name="recommenderEmail"
									placeholder={PLACEHOLDERS_TEXTS.recommenderEmail}
									isEmailField
									type="email"
								/>
								{isErrorRecommenderEmail && _.get(fields, 'recommenderEmail.touched') && (
									<p className={css.inputError}>{errors.recommenderEmail}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									(_.get(fields, 'recommenderRelationship.active', false) ||
										_.get(values, 'recommenderRelationship', '')) &&
										css.focusedStar,
									isErrorRecommenderRelationship &&
										_.get(fields, 'recommenderRelationship.touched') &&
										css.error
								)}
							>
								<Select
									name="recommenderRelationship"
									options={selectOpts2}
									placeholder="מהו הקשר שלך לגננת"
								/>
								{isErrorRecommenderRelationship && _.get(fields, 'recommenderRelationship.touched') && (
									<p className={css.inputError}>{errors.recommenderRelationship}</p>
								)}
							</div>
						</div>
					</div>
					<div className={css.formRow}>
						<div className={classNames(css.checkboxWrapper)}>
							<Checkbox
								name="isStateKindergarten"
								label={
									<>
										אני מצהיר/ה שמדובר בגן משרד חינוך שמופעל ע"י העירייה או הרשות המקומית לגילאי 3-6
										(טרום טרום חובה, טרום חובה וחובה או גן רב גילאי) - לא מדובר בגן
										פרטי/פעוטון/מעון.
									</>
								}
							/>
							{isStateKindergartenError && _.get(fields, 'isStateKindergarten.touched') && (
								<p className={css.error}>{errors.isStateKindergarten}</p>
							)}
							{/* <p className={css.checkBoxTextRequire}>שדות חובה</p> */}
						</div>
					</div>
					<div className={css.formRow}>
						<div className={classNames(css.checkboxWrapper)}>
							<Checkbox
								name="confirmCheckbox"
								label={
									// !isMobile ? (
									<>
										אני מסכים/ה{' '}
										<a href={termsPdfFile} target="_blank" rel="noreferrer">
											לתקנון{' '}
										</a>
										,{' '}
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html"
											target="_blank"
											rel="noreferrer"
										>
											תנאי השימוש{' '}
										</a>
										,
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html"
											target="_blank"
											rel="noreferrer"
										>
											ומדיניות הפרטיות
										</a>{' '}
										ולקבלת פניות שיווקיות כולל טלפוניות מ-ynet הנ"ל איננה הסכמה לעריכת עסקה.
									</>
								}
							/>
							{isErrorCheckbox && _.get(fields, 'confirmCheckbox.touched') && (
								<p className={css.error}>{errors.confirmCheckbox}</p>
							)}
							<p className={css.checkBoxTextRequire}>שדות חובה</p>
						</div>
					</div>

					<div className={classNames(css.formRow, css.withButton)}>
						<button type="submit" className={classNames(css.submitBtn)}>
							<div className={css.submitBtnInner}>
								{this.state.isLoading ? (
									<Icon type="preloader" className={css.preloader} />
								) : (
									<>
										<span>שליחה</span>
										<svg
											className={css.arrow}
											xmlns="http://www.w3.org/2000/svg"
											width="21"
											height="30"
											viewBox="0 0 21 30"
											fill="none"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M14.0059 8.25L15.4166 9.67452L8.90479 16.25L15.4166 22.8255L14.0059 24.25L6.08331 16.25L14.0059 8.25Z"
												fill="white"
											/>
										</svg>
									</>
								)}
							</div>
						</button>
					</div>
				</Form>

				{/* <ErrorsPopup
					className={!isUserNotified && !_.isEmpty(errors) ? 'show' : ''}
					closePopup={this.closeErrorPopup}
					errors={errosWithoutRadioBtns}
				/> */}
				<AlreadyInSystemPopup
					className={isShowAlreadyInSystemPopup ? 'show' : ''}
					closePopup={this.closeAlreadyInSystemPopup}
				/>
				<NotStartedPopup className="" />
				<SendedFormPopup className={isShowSenededFormPopup ? 'show' : ''} closePopup={this.closeSuccessPopup} />
			</div>
		);
	}
}

const mapState = (state: ClientStore) => {
	const values = 'form.userInfo.values';
	const errors = 'form.userInfo.syncErrors';
	const fields = 'form.userInfo.fields';

	return {
		values: _.get(state, `${values}`, {}),
		errors: _.get(state, `${errors}`, {}),
		fields: _.get(state, `${fields}`, {}),
	};
};

const mapDispatch = {
	initializeForm: initialize,
	createItem,
	resetForm: reset,
};

export default connect(mapState, mapDispatch)(ContentForm);
